import React, { Component } from 'react';
import 'intl';
import { Button, Card, CardText, Col, Container, Row } from 'reactstrap';
import * as _ from 'lodash';

import { Link } from 'gatsby';
import ContentPage from './contentPage';
import { FormattedMessage } from 'react-intl';

import '../assets/scss/pages/_JobsPage.scss';
import moment from 'moment';

class JobsPage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { jobs, preHeader, header, headerImage } = this.props;

    return (
      <ContentPage
        preHeader={preHeader}
        header={header}
        headerImage={headerImage}
        content={(
          <Container className="jobs-wrapper">
            <Row>
              {jobs.map((item) => {
                const row = item.node;

                const jobTitleForUrl = _.replace(_.lowerCase(row.frontmatter.jobTitle), /\s/g, '-');
                const path = `${row.frontmatter.seo.lang}/jobs/${jobTitleForUrl}`;
                return (
                  <Card
                    index={jobTitleForUrl}
                    tag={Col}
                    md={12}
                    body
                    className="text-center job-card"
                  >
                    <CardText className="text-left">
                      <Row>
                        <Col
                          md={6}
                        >
                          <h3>{row.frontmatter.jobTitle}</h3>
                        </Col>
                        <Col
                          md={3}
                          className="info-section mt-3 mt-md-0"
                        >
                          <i className="far fa-calendar-alt"/>
                          <span>Start:</span>
                          {moment(row.frontmatter.data).format('L')}
                        </Col>
                        <Col
                          md={3}
                          className="info-section mt-3 mt-md-0"
                        >
                          <i className="far fa-clock"/>
                          <span>Type:</span>
                          {row.frontmatter.jobType}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={6}
                          className="impect-text-blue mt-3 mt-md-0"
                        >
                          <i className="fas fa-map-marker-alt"/>
                          {row.frontmatter.location}
                        </Col>
                        <Col
                          md={3}
                          className="pl-0 mt-3 mt-md-0"
                        >
                          <Button
                            tag="a"
                            href="mailto:info@impect.com"
                            color="dark"
                          >
                            <FormattedMessage id="recommend"/>
                          </Button>
                        </Col>
                        <Col
                          md={3}
                          className="pl-0 mt-3 mt-md-0"
                        >
                          <Button
                            tag={Link}
                            to={path}
                          >
                            <FormattedMessage id="readMore"/>
                          </Button>
                        </Col>
                      </Row>
                    </CardText>
                  </Card>
                );
              })}
            </Row>

            <Row className="contact-section text-center">
              <Col>
                <h4>
                <span className="pre-header">
                  Impect
                </span>
                  <FormattedMessage id="jobsLookingFor"/>
                </h4>

                <Button
                  tag="a"
                  href="mailto:info@impect.com"
                >
                  <FormattedMessage id="contactUs"/>
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      />
    );
  }
}

export default JobsPage;
